.App {
  text-align: center;
  box-sizing: border-box !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.signup{
  background-color: rgb(255, 191, 77) !important;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
}
#submit{
 background-color: rgb(255, 191, 77);
 font-size: 18px;
 font-weight: 700;

}
.market-eval-subheads{
  font-size: 18px;
}
.market-eval-text{
  width: 50%; 
  height: 2rem; 
  font-size: 1.1rem;
   margin-top: 1rem;
  }
  @media only screen and (max-width: 900px) {
    .market-eval-text{
      width: 90%;
  
    }
  }
.market-eval-div{
width: 50%; 
margin: 6rem auto 4rem auto;
margin-top: 3rem; 
display: flex; 
flex-direction: column; 
align-items: space-evenly;
background-color: rgb(250,250,250);
box-shadow: 6px 7px 3px 1px #C6C6C6;
min-height: 300px;
}

@media only screen and (max-width: 900px) {
  .market-eval-div{
    width: 90%;
  }
}

.getting-started-stepper{
width: 40%;
margin: 6rem auto 4rem auto;
margin-top: 3rem; 
display: flex; 
flex-direction: column;
align-items: space-evenly;
background-color: rgb(250,250,250);
box-shadow: 6px 7px 3px 1px #C6C6C6;
min-height: 225px;
}

@media only screen and (max-width: 768px) {
  .getting-started-stepper{
    width: 100%;
  }
}
.login{
  padding-top: 10rem;
  padding-bottom: 10rem;
}
/* .mark-sub{

} */
.formContainer{
  background-color: rgb(250, 250, 250) !important;
  width: '100%'
}
.header-img{
  height: 20% !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  box-sizing: border-box !important;
}


.menu-items-dropdown:hover {
  font-weight:800 !important;
  color: black !important;
  cursor: pointer !important;
}